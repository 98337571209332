<template>
  <div class="div">
    <form ref="form" @submit.prevent="sendEmail">
      <!-- Name -->

      <div class="field">
        <input
          v-model="form.fullName"
          type="text"
          required
          autocomplete="off"
          name="fullName"
          id="fullName"
        />
        <label for="fullName" title="Name" data-title="Name"></label>
      </div>

      <!-- Company -->

      <div class="field mt-16">
        <input
          v-model="form.companyName"
          type="text"
          required
          autocomplete="off"
          name="company_name"
          id="company_name"
        />
        <label for="company" title="Company" data-title="Company"></label>
      </div>

      <!--email  -->

      <div class="field mt-16">
        <input
          v-model="form.email"
          type="text"
          required
          autocomplete="off"
          name="email"
          id="email"
        />
        <label for="email" title="Email" data-title="Email"></label>
      </div>

      <!-- Message -->
      <div class="field">
        <textarea
          v-model="form.message"
          class="message mt-7"
          name="message"
          id="message"
          required
        ></textarea>
        <label
          class="ml-2 messageLable"
          for="email"
          title="Message"
          data-title="Message"
        ></label>
      </div>

      <!-- <div
        class="g-recaptcha"
        data-sitekey="6LcwFg4iAAAAAJ-nu8v_hcHS1-p8v-vhZqd6mr8W"
      ></div> -->

      <button onclick="sendEmail()" class="sendBtn white--text">
        <v-span class=""> SEND</v-span>
      </button>
    </form>

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";
import axios from "axios";

export default {
  data() {
    return {
      overlay: false,
      form: {
        fullName: "",
        email: "",
        companyName: "",
        message: "",
      },
    };
  },

  methods: {
    async sendEmail() {
      try {
        this.overlay = true;
        var url = process.env.VUE_APP_AI_API_URL;
        var apiKey = process.env.VUE_APP_AI_API_KEY;

        const res = await axios.post(
          url + "emails/send-email",
          {
            name: this.form.fullName,
            email: this.form.email,
            // contact_number: this.form.contactNumber,
            company: this.form.companyName,
            message: this.form.companyName,
            website_name: "Softidoc",
          },
          {
            headers: {
              AppApiKey: apiKey,
              // "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.status == 200) {
          // console.log(res);

          this.$swal({
            icon: "success",
            title: "Thank you!",
            text: "Your submission has been sent.",
          });
        }
      } catch (error) {
        const res = error.response;
        const data = res.data;
        const status = error.response.status;
        console.log("error");

        if (status == 400) {
          // console.log(res);

          console.log(res);
          if ("errors" in data) {
            if ("name" in data.errors) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.errors.name[0],
              });
            } else if ("email" in data.errors) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.errors.email[0],
              });
            } else if ("message" in data.errors) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.errors.message[0],
              });
            }
          }
        } else if (status == 404) {
          console.log(res);

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Page not found",
            });
          }
        } else if (status == 422) {
          console.log(res);

          if ("message" in data) {
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: data.message,
            });
          }
        } else if (status == 500) {
          console.log(res);
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      } finally {
        this.overlay = false;
      }
    },

    sendEmails() {
      var serviceId = process.env.VUE_APP_YOUR_SERVICE_ID;
      var templateId = process.env.VUE_APP_YOUR_TEMPLATE_ID;
      var publicKey = process.env.VUE_APP_YOUR_PUBLIC_KEY;

      this.overlay = true;

      if (
        this.form.name === "" ||
        this.form.email === "" ||
        this.form.companyName === "" ||
        this.form.message === ""
      ) {
        this.$swal({
          icon: "error",
          title: "Request failed",
          text: "Please fill out all the fields",
        });
        this.overlay = false;
      } else if (!this.validName(this.form.name)) {
        this.$swal({
          icon: "error",
          title: "Request failed",
          text: "Name must contain only A to Z or a to z",
        });
        this.overlay = false;
      } else if (!this.validEmail(this.form.email)) {
        this.$swal({
          icon: "error",
          title: "Request failed",
          text: "Invalid email",
        });
        this.overlay = false;
      } else {
        emailjs
          .sendForm(serviceId, templateId, this.$refs.form, publicKey)
          .then(
            (result) => {
              this.$swal({
                icon: "success",
                title: "Thank you!",
                text: "Your submission has been sent.",
              });
              // alert("Request sent successfully ");
              console.log("SUCCESS!", result.text);
              this.overlay = false;
            },
            (error) => {
              // alert("failed");
              console.log("error...", error);
              // console.log("FAILED...", error.text);

              this.$swal({
                icon: "error",
                title: "Request failed",
                text: "An internal error occurred during your request!",
              });

              this.overlay = false;
            }
          );
      }

      // this.overlay = true;

      // emailjs.sendForm(serviceId, templateId, this.$refs.form, publicKey).then(
      //   (result) => {
      //     this.$swal({
      //       icon: "success",
      //       title: "Thank you!",
      //       text: "Your submission has been sent.",
      //     });
      //     console.log("SUCCESS!", result.text);

      //     this.overlay = false;
      //   },
      //   (error) => {
      //     console.log("error...", error);

      //     this.$swal({
      //       icon: "error",
      //       title: "Request failed",
      //       text: "An internal error occurred during your request!",
      //     });

      //     this.overlay = false;
      //   }
      // );
    },

    // validName: function (name) {
    //   var re = /^[a-zA-Z\s]+$/.test(name);
    //   return re.test(name);
    // },

    validName: function (fullName) {
      var nameVar = /^[a-zA-Z\s]+$/;
      return nameVar.test(fullName);
    },

    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
input,
textarea {
  overflow-wrap: break-word;
  align-items: center;
  background: none;
  color: white;
  font-size: 18px;
  padding: 10px 10px 10px 15px;
  width: 100%;
  border-bottom: 1px solid white;
}

// form
.label-before {
  line-height: 20px;
  font-size: 12px;
  top: -10px;

  padding: 0 6px;
}

.field {
  position: relative;
  margin-top: 40px;

  // BEFOR YOU ENTER DETEILS
  label::before {
    font-weight: bold;

    content: attr(title);
    position: absolute;
    top: 0;
    left: 1px;
    line-height: 40px;
    font-size: 24px; //size of label befor filling fields
    color: white;
    transition: 300ms all;
  }
  .messageLable::before {
    content: attr(title);
    position: absolute;
    top: 0;
    left: 1px;
    line-height: 40px;
    font-size: 24px; //size of message label befor filling fields
    color: white;
    transition: 300ms all;
    margin-top: 30px;
    margin-left: 10px;
  }

  input,
  textarea {
    width: 100%;
    line-height: 40px;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 24px; //size of inputs
    color: white;
    border-bottom: 1px solid #ccc;
    // border-radius: 3px;

    &:focus {
      outline: 0;
      border-color: white;
    }
    &:valid + label::before {
      @extend .label-before;
      content: attr(data-title);
      font-size: 20px; //size of label aftyer filling fields

      margin-top: -14px;
      margin-left: 1px;
      // line-height: -90px;
      color: orange;
      font-weight: bold;
    }
  }

  textarea {
    &:valid + label::before {
      @extend .label-before;
      content: attr(data-title);
      margin-top: -1px;
      margin-left: 2px;
    }
  }
}

.message {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 20px;
  resize: none;
}
.sendBtn {
  margin-top: 15px;
  border: 1px solid white;
  width: 100%;
  height: 50px;
  border-radius: 25px;
}
textarea:focus,
input:focus {
  outline: none;
}
@media screen and (max-width: 375px) {
  .label-before {
    line-height: 20px;
    font-size: 12px;
    top: -10px;

    padding: 0 6px;
  }

  .field {
    // BEFOR YOU ENTER DETEILS
    label::before {
      font-size: 13px; //size of label befor filling fields
    }
    .messageLable::before {
      content: attr(title);
      font-size: 13px; //size of message label befor filling fields
    }

    input,
    textarea {
      font-size: 13px; //size of inputs
      &:valid + label::before {
        @extend .label-before;
        font-size: 11.66px; //size of label aftyer filling fields
      }
    }
  }
}
</style>
