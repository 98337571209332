<template>
  <div class="nav">
    <div
      class="bg white--text"
      :style="{ backgroundImage: 'url(' + homeBk + ')' }"
    >
      <v-card
        class="custom-card hidden-sm-and-down d-flex transparent"
        align="right"
        flat
        tile
      >
        <v-card
          class="card-Control-SideLine hidden-sm-and-down transparent"
          flat
          align="center"
          justify="center"
        >
          <!-- What we do Side Details -->

          <v-card
            class="transparent hidden-sm-and-down sideCard1"
            flat
          ></v-card>
          <p class="pageName white--text mb-n16">What we do</p>
          <v-img
            class="homeBackground white--text ml-n1 mt-n16"
            src="@/assets/img/Home/Line.png"
            width="10px"
          />

          <!-- Our Products Side Details -->

          <v-card
            class="transparent hidden-sm-and-down sideCard2"
            flat
          ></v-card>
          <p class="pageName white--text">Our Products</p>
          <v-img
            class="homeBackground white--text ml-n1 mt-n16"
            src="@/assets/img/Home/Line.png"
            width="10px"
          />

          <!-- Our Products Side Details -->

          <v-card
            class="transparent hidden-sm-and-down sideCard3"
            flat
          ></v-card>
          <p class="pageName white--text mb-n16">Contact Us</p>
          <v-img
            class="homeBackground white--text ml-n1 mt-n16"
            src="@/assets/img/Home/Line.png"
            width="10px"
          />
        </v-card>

        <v-card class="custom-card2 hidden-sm-and-down" flat tile> </v-card>
      </v-card>

      <v-container class="d-flex mt-16">
        <div class="">
          <v-img
            alt="Logo"
            class="phone1"
            src="@/assets/img/Home/section1/phone.png"
          />
        </div>

        <div class="">
          <v-img
            alt="Logo"
            class="mt-7 softidoc"
            contain
            src="@/assets/img/SoftiDoc2.png"
          />
        </div>
      </v-container>

      <v-container class="text-left white--text">
        <!-- <p class="paragraph-p1">About</p> -->
        <h1 class="heading-h1 mb-3">
          Fintech solutions enhanced with AI for the Insurance, Banking and
          Lending Sectors
        </h1>
        <label class="heading-h2">
          We offer Fintech Solutions, enabling our clients to carry out a range
          of operations such as Lending, Investing, Fund Transfers and Banking.
        </label>
      </v-container>

      <v-container class="topMergin text-left">
        <v-row class="mt-md-16 mt-2">
          <v-col cols="12" md="4">
            <h2 class="heading-h4">
              Artificial Intelligence<br />
              Solutions
            </h2>
          </v-col>

          <v-col cols="12" md="5">
            <p class="paragraph-p2">
              We are an AI solutions provider specializing in a boutique of
              products to achieve financial inclusion. We specialize in
              composite application development and complex system integration
              leveraging artificial intelligence to address banking and
              insurance challenges and needs. Artificial intelligence and
              Cognitive Computing focuses on understanding and revealing the
              power of real-time data through the use of cognitive systems and
              then using this information to enhance business processes and
              decision making. This approach enables innovation through the use
              and application of existing and future data.
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="text-left mt-md-8">
        <v-row>
          <v-col class="hidden-sm-and-down" cols="12" md="3"></v-col>
          <v-col cla cols="12" md="4">
            <h2 class="heading-h44">Digital Core Banking</h2>
          </v-col>

          <v-col cols="12" md="5"
            ><p class="paragraph-p2">
              With the transition of banks now moving to digital we have
              developed a digital core banking system that incorporates all our
              AI prebuilt boutique components as to dramatically accelerate this
              process. Our products combine bank functionality and the most
              advanced cloud-native, cloud agnostic, AI and API-first technology
              to help our clients go live faster. Also toward the vision of the
              complete digital bank, the gains brought on by digital automation
              lowers operation costs and brings long term gain allowing services
              to people who are not visible in traditional financial systems.
            </p></v-col
          >
        </v-row>
      </v-container>
      <v-img
        class="pricingBackground white--text hidden-sm-and-down"
        src="@/assets/img/Background/Background Rings.png"
        contain
        height="950"
        width="100%"
      >
        <v-container class="mt-16 text-left">
          <p class="paragraph-p1">Products</p>
          <h2 class="heading-h3">Our products include:</h2>

          <v-row class="mt-10">
            <v-col class="hidden-sm-and-down" cols="12" md="5"> </v-col>
            <v-col class="" cols="12" md="5">
              <v-card class="card1 px-2 white--text">
                <div class="d-flex">
                  <div>
                    <v-img
                      alt="Logo"
                      src="@/assets/img/Home/section3/Vecto1.png"
                      contain
                      width="50"
                    />
                  </div>

                  <div class="ml-5">
                    <p class="heading-h8 mt-2">Pre-Built AI Components</p>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="my-6">
            <v-col cols="12" md="5">
              <v-card class="card2 px-2 white--text">
                <div class="d-flex">
                  <div>
                    <v-img
                      alt="Logo"
                      src="@/assets/img/Home/section3/Vector2.png"
                      contain
                      width="50"
                    />
                  </div>

                  <div class="ml-5 mt-n4">
                    <p class="heading-h8">
                      A Range of Multiple Lending Methodologies
                    </p>
                  </div>
                </div>
              </v-card>
            </v-col>

            <v-col class="hidden-sm-and-down" cols="12" md="2"></v-col>
            <v-col class="mt-10" cols="12" md="5">
              <v-card class="card2 px-2 white--text">
                <div class="d-flex">
                  <div>
                    <v-img
                      alt="Logo"
                      src="@/assets/img/Home/section3/Vector3.png"
                      contain
                      width="50"
                      class="mt-1"
                    />
                  </div>

                  <div class="ml-5">
                    <p class="heading-h8">A Full Range of Deposit Products</p>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="hidden-sm-and-down" cols="12" md="2"> </v-col>
            <v-col cols="12" md="5">
              <v-card class="card2 px-2 white--text">
                <div class="d-flex">
                  <div>
                    <v-img
                      alt="Logo"
                      src="@/assets/img/Home/section3/Vector4.png"
                      contain
                      width="50"
                    />
                  </div>

                  <div class="ml-5">
                    <p class="heading-h8 mt-md-3">
                      Multiple Insurance Products
                    </p>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-card
            class="transparent hidden-sm-and-down"
            height="150"
            flat
          ></v-card>
        </v-container>
      </v-img>

      <v-container class="mt-16 text-left hidden-md-and-up">
        <p class="paragraph-p1">Products</p>
        <h2 class="heading-h3">Our products include:</h2>

        <v-row class="mt-10">
          <v-col class="hidden-sm-and-down" cols="12" md="5"> </v-col>
          <v-col class="" cols="12" md="5">
            <v-card class="card1 px-2 white--text">
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    src="@/assets/img/Home/section3/Vecto1.png"
                    contain
                    width="50"
                  />
                </div>

                <div class="ml-5">
                  <p class="heading-h8">Pre-Built AI Components</p>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="my-6">
          <v-col cols="12" md="5">
            <v-card class="card2 px-2 white--text">
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    src="@/assets/img/Home/section3/Vector2.png"
                    contain
                    width="50"
                  />
                </div>

                <div class="ml-5 mt-n1">
                  <p class="heading-h8">
                    A Range of Multiple Lending Methodologies
                  </p>
                </div>
              </div>
            </v-card>
          </v-col>

          <v-col class="hidden-sm-and-down" cols="12" md="2"></v-col>
          <v-col class="mt-10" cols="12" md="5">
            <v-card class="card2 px-2 white--text">
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    src="@/assets/img/Home/section3/Vector3.png"
                    contain
                    width="50"
                    class="mt-1"
                  />
                </div>

                <div class="ml-5">
                  <p class="heading-h8">A Full Range of Deposit Products</p>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="hidden-sm-and-down" cols="12" md="2"> </v-col>
          <v-col cols="12" md="5">
            <v-card class="card2 px-2 white--text">
              <div class="d-flex">
                <div>
                  <v-img
                    alt="Logo"
                    src="@/assets/img/Home/section3/Vector4.png"
                    contain
                    width="50"
                  />
                </div>

                <div class="ml-5">
                  <p class="heading-h8 mt-md-3">Multiple Insurance Products</p>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-card
          class="transparent hidden-sm-and-down"
          height="150"
          flat
        ></v-card>
      </v-container>

      <v-container class="mt-16 text-left">
        <v-row>
          <!--side message appear when is on mobile -->

          <v-col class="mt-xl-2 hidden-md-and-up" cols="12" md="6">
            <h1 class="heading-h33">
              “The major winners will be financial services companies that
              embrace technology.”
            </h1>
            <p class="grey--text mt-3">Alexander Peh, Paypal and Braintree</p>
          </v-col>

          <v-col cols="12" md="5">
            <h2 class="heading-h3">Want to know more?</h2>
            <p>
              Contact us by completing the below fields and we will get back to
              you as soon as possible!
            </p>
            <ContactForm />
          </v-col>

          <v-col class="hidden-sm-and-down" cols="12" md="1"></v-col>
          <v-col class="mt-xl-2 hidden-sm-and-down" cols="12" md="6">
            <h1 class="heading-h33">
              “The major winners will be financial services companies that
              embrace technology.”
            </h1>
            <p class="grey--text mt-3">Alexander Peh, Paypal and Braintree</p>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Overlay -->
    <v-overlay :value="overlay" z-index="10">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import homeBk from "@/assets/img/Background/landingBack.svg";
import ContactForm from "@/components/shared/ContactForm.vue";
import emailjs from "@emailjs/browser";
export default {
  name: "HomePage",

  data() {
    return {
      overlay: false,
      homeBk: homeBk,
    };
  },
  components: { ContactForm },
  methods: {
    sendEmail() {
      var serviceId = process.env.VUE_APP_YOUR_SERVICE_ID;
      var templateId = process.env.VUE_APP_YOUR_TEMPLATE_ID;
      var publicKey = process.env.VUE_APP_YOUR_PUBLIC_KEY;
      // alert("successssss");
      // var params = {
      //   full_name: this.fullName,
      //   contact_number: this.contactNumber,
      //   company_name: this.companyName,
      //   email: this.email,
      //   message: this.message,
      // };
      //  emailjs.sendForm(serviceId, templateId, this.$refs.form, publicKey).then(

      this.overlay = true;

      emailjs.sendForm(serviceId, templateId, this.$refs.form, publicKey).then(
        (result) => {
          this.$swal({
            icon: "success",
            title: "Request sent successfully",
            text: "bjdbfbufn",
          });
          console.log("SUCCESS!", result.text);

          this.overlay = false;
        },
        (error) => {
          console.log("error...", error);
          // console.log("FAILED...", error.text);
          this.$swal({
            icon: "error",
            title: "Request sent successfully",
            text: "bjdbfbufn",
          });

          this.overlay = false;
        }
      );

      // this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  background-size: cover;
  background-position: top;
  height: 3400px;
  width: 100%;
}
.heading-h4 {
  background: linear-gradient(116.43deg, #6378f6 16.14%, #e126ff 89.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-h44 {
  background: linear-gradient(116.43deg, #3391ff 21.08%, #ffffff 94.34%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card2 {
  background: linear-gradient(116.43deg, #3391ff 27.46%, #ffa126 94.34%);
  padding-top: 30px;
  padding-bottom: 30px;
  width: 532px;
  height: 115px;
  border-radius: 16px;
}
.card1 {
  background: linear-gradient(116.43deg, #6378f6 16.14%, #e126ff 89.71%);
  padding-top: 30px;
  padding-bottom: 30px;
  width: 532px;
  height: 115px;
  border-radius: 16px;
}

.custom-card {
  // margin-top: -3900px;
  //z-index: 1;
  height: 40px;
  width: 100px;
  // width: 10%;
  background: transparent;
  // position: relative;
}
.custom-card2 {
  width: 2px;
  height: 3400px;
  background-color: #82869a;
  // opacity: 0.2;
  // position: fixed;
}

.sideCard1 {
  height: 880px;
}

.sideCard2 {
  height: 1080px;
}

.sideCard3 {
  height: 850px;
}

@media screen and (max-width: 1440px) {
  .bg {
    background-size: cover;
    background-position: top;
    height: 3500px;
    width: 100%;
  }
  .custom-card2 {
    width: 2px;
    height: 3500px;
    background-color: #82869a;
    // opacity: 0.2;
    // position: fixed;
  }
  // .custom-card2 {
  //   width: 2px;
  //   height: 3400px;
  //   background-color: #82869a;
  // }
}

@media screen and (max-width: 1024px) {
  .bg {
    background-size: cover;
    background-position: top;
    height: 3640px;
    width: 100%;
  }

  .custom-card {
    width: 80px;
    position: relative;
  }
  .custom-card2 {
    height: 3640px;
    // opacity: 0.2;
    // position: fixed;
  }

  .sideCard2 {
    height: 1320px;
  }

  .sideCard3 {
    height: 770px;
  }
}

@media screen and (max-width: 768px) {
  .bg {
    background-size: cover;
    background-position: top;
    height: 3300px;
    width: 100%;
  }

  .custom-card {
    height: 0px;
  }
}
@media screen and (max-width: 425px) {
  .bg {
    background-size: cover;
    background-position: top;
    height: 3350px;
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  .bg {
    background-size: cover;
    background-position: top;
    height: 3500px;
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .card2 {
    background: linear-gradient(116.43deg, #3391ff 27.46%, #ffa126 94.34%);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 532px;
    height: 70px;
    border-radius: 16px;
  }
  .card1 {
    background: linear-gradient(116.43deg, #6378f6 16.14%, #e126ff 89.71%);
    padding-top: 10px;
    padding-bottom: 10px;
    width: 532px;
    height: 70px;
    border-radius: 16px;
  }
  .bg {
    background-size: cover;
    background-position: top;
    height: 3300px;
    width: 100%;
  }
}
</style>
