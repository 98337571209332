<template>
  <div class="primary">
    <v-container class="text-left pt-16 white--text">
      <v-row>
        <v-col cols="4" md="2">
          <router-link class="text-decoration-none white--text" to="products"
            ><h3>Products</h3></router-link
          >
          <router-link class="text-decoration-none white--text" to="pricing"
            ><h3>Pricing</h3></router-link
          >
          <router-link class="text-decoration-none white--text" to="news"
            ><h3>News</h3></router-link
          >
          <router-link class="text-decoration-none white--text" to="resources"
            ><h3>Resources</h3></router-link
          >
        </v-col>

        <v-col cols="7" md="2">
          <h3>Contact</h3>
          <p>info@softidoc.co.za</p>
        </v-col>

        <!-- Location SA -->
        <v-col cols="7" md="2">
          <!-- <h3>Office Info</h3> -->
          <h3>Location SA</h3>
          <p>
            Suite 15, Block 10,<br />
            Boardwalk Office Park,<br />
            Eros Road, Olympus AH,<br />
            0081
          </p>
        </v-col>

        <!-- Location UK -->
        <v-col class="pl-md-13" cols="7" md="2">
          <!-- <h3>Office Info</h3> -->
          <h3>Location UK</h3>
          <p>
            32 Alpine Avenue Tolworth<br />
            London<br />
            KT59RJ
          </p>
        </v-col>
        <v-col class="hidden-sm-and-down" cols="6" md="1"> </v-col>
        <v-col cols="6" md="2">
          <v-img
            alt="Logo"
            class="mt-2"
            width="140px"
            src="@/assets/img/SoftiDoc.png"
            contain
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="text-right white--text hidden-sm-and-down pr-13">
      <p>Softidoc (Pty) Ltd</p>
    </v-container>
    <v-container class="text-left white--text hidden-md-and-up">
      <p>Softidoc (Pty) Ltd</p>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HomePage",

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
//
</style>
