<template>
      <iframe
      width="100%"
      height="750"
      :src="pdfUrl"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </template>
  
  <script>
  import { saveAs } from 'file-saver';
  export default {

    data() {
    return {
      pdfUrl: '/JA_SoftiDoc_Brochure_MB.pdf' , 
    };
  },
  created() {
    this.downloadPdf();
  },
    methods: {
      downloadPdf() {
        saveAs(this.pdfUrl, 'JA_SoftiDoc_Brochure_MB.pdf');
      },
    },
  };
  </script>
  