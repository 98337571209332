import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentPage: "home",
  },
  getters: {},

  mutations: {
    setCurrentPage(state, payload) {
      state.currentPage = payload;
    },
  },

  actions: {},

  modules: {},

  plugins: [createPersistedState()],
});
