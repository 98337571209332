<template>
  <div class="nav">
    <v-container class="">
      <v-row class="py-5 white--text">
        <v-btn
          @click.stop="drawer = !drawer"
          icon
          class="hidden-md-and-up mb-5 ml-3 mr-4 mt-2"
          width="18"
          color="white"
        >
          <v-icon> mdi-menu </v-icon>
        </v-btn>

        <router-link class="omniLogo text-decoration-none" to="/">
          <!-- <v-toolbar-side-icon> -->
          <v-img
            alt="Logo"
            class="SoftidocLogo"
            src="@/assets/img/SoftiDoc.png"
          />
          <!-- </v-toolbar-side-icon> -->
        </router-link>
        <v-spacer></v-spacer>

        <div class="">
          <v-row class="navB nav-link">
            <!-- Home -->

            <router-link
              class="routerNav text-decoration-none white--text"
              to="/"
              @click="setCurrentPage('home')"
            >
              <p class="mr-2 mt-7 hidden-sm-and-down">Home</p>
              <v-card
                v-if="currentPage == 'home'"
                class="transparent mt-n4 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot orange"></v-card
              ></v-card>
            </router-link>

            <div class="text-center productClass mr-9 mt-7 hidden-sm-and-down">
              <v-menu
                open-on-hover
                transition="slide-y-transition"
                nudge-left="70"
                nudge-bottom="25"
              >
                <!-- <v-menu transition="slide-y-transition" open-on-hover offset-y> -->

                <template v-slot:activator="{ on, attrs }">
                  <router-link
                    class="routerNav text-decoration-none white--text"
                    to="products"
                    @click="setCurrentPage('products')"
                  >
                    <p v-bind="attrs" v-on="on">Products</p>
                  </router-link>
                </template>

                <v-list class="px-2 text-center" color="primary ">
                  <v-list-item
                    href="https://documentextraction.softidoc.co.za/"
                    class="primary white--text"
                  >
                    <v-list-item-title>
                      <p>Document Extraction</p>
                    </v-list-item-title>
                  </v-list-item>
                  <v-card
                    class="mb-1"
                    color="#82869a"
                    width="100%"
                    height="2px"
                  ></v-card>
                  <v-list-item
                    class="primary white--text"
                    href=" https://ols.softidoc.co.za"
                  >
                    <v-list-item-title class=""> <p>OLS</p> </v-list-item-title>
                  </v-list-item>
                  <v-card
                    class="mt-n2 mb-1"
                    color="#82869a"
                    width="100%"
                    height="2px"
                  ></v-card>
                  <!-- <v-card
                    class="mt-n2 mb-1"
                    color="#82869a"
                    width="100%"
                    height="2px"
                  ></v-card> -->

                  <v-list-item
                    class="primary white--text pl-0"
                    :to="{ name: 'financialServ' }"
                    @click="setCurrentPage('financialServ')"
                  >
                    <v-list-item-title>
                      <p>Chatbot</p>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <!-- Pricing -->

            <router-link
              class="routerNav text-decoration-none white--text"
              to="pricing"
              @click="setCurrentPage('pricing')"
              ><p class="ma-2 mt-7 hidden-sm-and-down">Pricing</p>

              <v-card
                v-if="currentPage == 'pricing'"
                class="transparent mt-n2 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot orange"></v-card
              ></v-card>
            </router-link>

            <!-- news -->

            <router-link
              class="routerNav text-decoration-none white--text"
              to="news"
              @click="setCurrentPage('news')"
              ><p class="ma-2 mt-7 hidden-sm-and-down">News</p>

              <v-card
                v-if="currentPage == 'news'"
                class="transparent mt-n2 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot orange"></v-card
              ></v-card>
            </router-link>

            <!-- resources -->

            <router-link
              class="routerNav text-decoration-none white--text"
              to="resources"
              @click="setCurrentPage('resources')"
              ><p class="ma-2 mt-7 hidden-sm-and-down">Resources</p>
              <v-card
                v-if="currentPage == 'resources'"
                class="transparent mt-n2 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot orange"></v-card
              ></v-card>
            </router-link>

            <!-- contact us -->

            <router-link
              class="routerNav text-decoration-none white--text"
              to="contacts"
              @click="setCurrentPage('contacts')"
            >
              <p class="mt-7">Contact Us</p>
              <v-card
                v-if="currentPage == 'contacts'"
                class="transparent mt-n4 hidden-sm-and-down"
                align="center"
                flat
                ><v-card class="dot orange"></v-card
              ></v-card>
            </router-link>
          </v-row>
        </div>
      </v-row>

      <!-- side bar -->

      <v-navigation-drawer
        class="primary sideBar text-justify"
        v-model="drawer"
        absolute
        temporary
      >
        <v-img
          class="mt-10"
          alt="Logo"
          src="@/assets/img/SoftiDoc.png"
          contain
          height="30"
        />

        <v-divider class="mt-4 white"></v-divider>

        <v-list nav dense>
          <v-list-item class="d-flex justify-end my-0">
            <v-icon @click="drawer = !drawer" color="white"
              >mdi-close-thick</v-icon
            >
          </v-list-item>
          <v-list-item-group class="mt-4 white--text" v-model="group">
            <v-list-item
              class="white--text"
              exact-active-class="transparent orange--text"
              to="/"
            >
              <v-list-item-title> <p>Home</p> </v-list-item-title>
            </v-list-item>

            <!-- <v-list-item
              class="white--text"
              exact-active-class="transparent orange--text"
              to="products"
            >
              <v-list-item-title> <p>Products</p> </v-list-item-title>
            </v-list-item> -->

            <v-menu transition="slide-y-transition" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <p class="ml-2" v-bind="attrs" v-on="on">Products</p>
              </template>

              <v-list class="px-2" color="primary ">
                <v-list-item
                  href="https://documentextraction.softidoc.co.za/"
                  class="primary white--text text-left"
                >
                  <v-list-item-title>
                    <p>Document Extraction</p>
                  </v-list-item-title>
                </v-list-item>

                <v-card
                  class="mb-1"
                  color="#82869a"
                  width="100%"
                  height="2px"
                ></v-card>
                <v-list-item
                  href="https://ols.softidoc.co.za"
                  class="primary white--text text-left"
                >
                  <v-list-item-title>
                    <p>OLS</p>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                  class="primary white--text pl-0"
                  href=" https://ols.softidoc.co.za"
                >
                  <v-list-item-title class="">
                    <p>OLS</p>
                  </v-list-item-title>
                </v-list-item> -->
                <v-card
                  class="mb-1"
                  color="#82869a"
                  width="100%"
                  height="2px"
                ></v-card>

                <v-list-item
                  :to="{ name: 'financialServ' }"
                  class="primary white--text text-left"
                >
                  <v-list-item-title>
                    <p>Chatbot</p>
                  </v-list-item-title>
                </v-list-item>

                <!-- <v-list-item
                  class="primary white--text pl-0"
                  :to="{ name: 'financialServ' }"
                >
                  <v-list-item-title class="" to="">
                    <p>Chatbot</p>
                  </v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>

            <v-list-item
              class="white--text"
              exact-active-class="transparent orange--text"
              to="pricing"
            >
              <v-list-item-title> <p>Pricing</p> </v-list-item-title>
            </v-list-item>

            <v-list-item
              class="white--text"
              exact-active-class="transparent orange--text"
              to="news"
            >
              <v-list-item-title> <p>News</p> </v-list-item-title>
            </v-list-item>

            <v-list-item
              class="white--text"
              exact-active-class="transparent orange--text"
              to="resources"
            >
              <v-list-item-title> <p>Resources</p> </v-list-item-title>
            </v-list-item>

            <v-list-item
              class="white--text"
              exact-active-class="transparent orange--text"
              to="contacts"
            >
              <v-list-item-title> <p>Contact Us</p> </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "NavBarSection",

  data: () => ({
    drawer: false,
    mini: true,
    offset: true,
    group: null,
    items: [
      { title: "Home" },
      { title: "Products" },
      { title: "Pricing" },
      { title: "News" },
      { title: "Resources" },
    ],

    productItems: [
      // { title: "Ledgers", icon: "mdi-scale-balance", link: "/ledgers" },

      {
        title: "Document Extraction",
        link: "https://documentextraction.softidoc.co.za/",
      },
      { title: "OLS", link: "https://ols.softidoc.co.za" },
      { title: "chatbot", to: "financialServ" },
    ],
  }),

  computed: {
    ...mapState(["currentPage"]),
  },

  methods: {
    ...mapMutations({
      setCurrentPage: "setCurrentPage",
    }),
  },
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  // justify-content: space-around;
  z-index: 3;
  background-color: #060d36;
  width: 100%;
  height: 100px;
  border-bottom: 2px solid #82869a;
}

.dot {
  width: 8px;
  height: 8px;
  // border-radius: 20px;
}
.omniLogo {
  margin-top: 1px;
}

.sideBar {
  position: fixed;
}
.imgId {
  height: 33px;
  width: 235px;
}
.callBackCard {
  width: 136px;
  margin-top: 25px;
  margin-left: 0px;
  margin-right: 0px;
}
.dropBar {
  font-size: 17px;
}
.dropDownText {
  font-size: 17px;
  margin-top: 5px;
}
p {
  font-size: 20px;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 26px;
}
.softidocClass {
  display: none;
}
.productClass:hover .softidocClass {
  display: block;
}

@media screen and (max-width: 1265px) {
  .SoftidocLogo {
    width: 170px;
  }
}
@media screen and (max-width: 1024px) {
  .SoftidocLogo {
    width: 120px;
  }
  .omniLogo {
    margin-top: 9px;
  }
  p {
    font-size: 15px;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 26px;
  }
}

@media screen and (max-width: 768px) {
  .SoftidocLogo {
    width: 90px;
  }

  .omniLogo {
    margin-top: 14px;
  }
  .callBackCard {
    margin-top: 25px;
    margin-left: 0px;
  }
  .nav {
    height: 90px;
  }
}

@media screen and (max-width: 425px) {
  .callBackCard {
    margin-top: -42px;
    margin-left: 65px;
  }
  .SoftidocLogo {
    width: 46px;
    margin-top: 4px;
  }
}

@media screen and (max-width: 375px) {
  .callBackCard {
    margin-top: -42px;
    margin-left: 20px;
  }
}

@media screen and (max-width: 320px) {
  .callBackCard {
    margin-top: -45px;
    margin-left: 185px;
  }
  .nav {
    height: 80px;
  }
}
</style>
