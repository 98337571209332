<template>
  <v-app>
    <v-main class="top-section text-center">
      <NavBar />
      <Section1 />
      <!-- <Section2 /> -->
      <!-- <Section3 />
      <Section4 />  -->
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import { mapMutations } from "vuex";
// @ is an alias to /src
import NavBar from "@/components/NavBar.vue";
import Section1 from "@/layouts/Home/Section1.vue";
// import Section2 from "@/layouts/Home/Section2.vue";
// import Section3 from "@/layouts/Home/Section3.vue";
// import Section4 from "@/layouts/Home/Section4.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    Section1,
    // Section2,
    // Section3,
    // Section4,
    Footer,
  },

  created() {
    this.setCurrentPage("home");
  },

  methods: {
    ...mapMutations({
      setCurrentPage: "setCurrentPage",
    }),
  },
};
</script>
