import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/layoutsView/Home/Home.vue";
import pdf from "@/views/layoutsView/downloadPdf/PdfDownload.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/products",
    name: "products",
    component: () => import("@/views/layoutsView/Products/products.vue"),
  },

  // {
  //   path: "/chat-board",
  //   name: "financialServ",
  //   component: () => import("@/views/layoutsView/Products/FinancialServ.vue"),
  // },
  {
    path: "/chat-board",
    name: "financialServ",

    component: () => import("@/views/layoutsView/Products/FinancialServ.vue"),
  },

  {
    path: "/pricing",
    name: "pricing",
    component: () => import("@/views/layoutsView/Pricing/pricing.vue"),
  },

  {
    path: "/news",
    name: "news",
    component: () => import("@/views/layoutsView/News/newsView.vue"),
  },

  {
    path: "/resources",
    name: "resources",
    component: () => import("@/views/layoutsView/Resources/Resources.vue"),
  },

  {
    path: "/contacts",
    name: "contacts",
    component: () => import("@/views/layoutsView/Contacts/contacts.vue"),
  },
  {
    path: '/download-pdf',
    component: pdf,
  },
  //all project must have it
  {
    path: "/:catchAll(.*)",
    redirect: { name: "home" },
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  linkExactActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
